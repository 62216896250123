import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-scroll";
import "./Header.css";
import { AuthContext } from "../../Context/Context";
import { ToggleButton } from "primereact/togglebutton";

const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const { t, i18n } = useContext(AuthContext);
  const [showMenu, setShowMenu] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      style={{
        direction: i18n.language === "ar" ? "rtl" : "",
      }}
      className={`${scrollPosition > 5 ? "scrollClassName" : ""}`}
    >
      <div className="nav_header">
        <div className="nav_logo">
          <Link to="home">
            <img
              style={{
                width: i18n.language === "ar" ? "15%" : "",
              }}
              src={require("../../assets/image/Logo Elite services -.png")}
              alt="Elite services"
            />
          </Link>
        </div>
        <div
          className="nav_menu_btn"
          onClick={(e) => {
            setShowMenu(!showMenu);
          }}
        >
          <i className={`${showMenu ? "ri-close-line" : "ri-menu-4-line"}`}></i>
        </div>
      </div>
      <ul className={`nav_links ${showMenu ? "open" : ""}`}>
        <li>
          <Link
            to="home"
            className={`nav-link ${i18n.language === "ar" ? "arLang" : ""}`}
            onClick={(e) => {
              setShowMenu(!showMenu);
            }}
            spy={true}
            smooth={true}
            offset={-100}
            duration={400}
          >
            {t("menu.home")}
          </Link>
        </li>
        <li>
          <Link
            to="about"
            className={`nav-link ${i18n.language === "ar" ? "arLang" : ""}  `}
            onClick={() => {
              setShowMenu(!showMenu);
            }}
            spy={true}
            smooth={true}
            offset={-100}
            duration={400}
          >
            {t("menu.About")}
          </Link>
        </li>
        <li>
          <Link
            to="services"
            className={`nav-link ${i18n.language === "ar" ? "arLang" : ""}`}
            onClick={() => {
              setShowMenu(!showMenu);
            }}
            spy={true}
            smooth={true}
            offset={-100}
            duration={400}
          >
            {t("menu.service")}
          </Link>
        </li>
        <li>
          <Link
            to="contact"
            className={`nav-link ${i18n.language === "ar" ? "arLang" : ""}`}
            onClick={() => {
              setShowMenu(!showMenu);
            }}
            spy={true}
            smooth={true}
            offset={-100}
            duration={400}
          >
            {t("menu.contact")}
          </Link>
        </li>
        <li>
          <div className="nav_btns">
            <ToggleButton
              onLabel="AR"
              offLabel="EN"
              onIcon="pi pi-globe"
              offIcon="pi pi-globe"
              checked={checked}
              onChange={(e) => {
                if (i18n.language === "en" && e.value === true) {
                  i18n.changeLanguage("ar");
                  setChecked(e.value);
                } else {
                  i18n.changeLanguage("en");
                  setChecked(e.value);
                }
              }}
            />
          </div>
        </li>
      </ul>
      <div className="nav_btns">
        <ToggleButton
          onLabel="AR"
          offLabel="EN"
          onIcon="pi pi-globe"
          offIcon="pi pi-globe"
          checked={checked}
          onChange={(e) => {
            if (i18n.language === "en" && e.value === true) {
              i18n.changeLanguage("ar");
              setChecked(e.value);
            } else {
              i18n.changeLanguage("en");
              setChecked(e.value);
            }
          }}
        />
      </div>
    </nav>
  );
};

export default Header;
