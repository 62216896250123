import React, { useContext } from "react";
import "./Footer.css";
import { AuthContext } from "../../Context/Context";

const Footer = () => {
  const { t, i18n } = useContext(AuthContext);
  return (
    <div
      className="footer section_container"
      style={{
        fontFamily: i18n.language === "ar" ? "Cairo" : "",
        direction: i18n.language === "ar" ? "" : "",
        letterSpacing: i18n.language === "ar" ? "0" : "",
        fontSize: i18n.language === "ar" ? "26px" : "",
        padding: i18n.language === "ar" ? "2rem 1rem" : "",
      }}
    >
      {i18n.language === "ar"
        ? "2024 © EliteServices جميع الحقوق محفوظة لشركة "
        : `Copyright © 2024 EliteServices. All rights reserved.`}
    </div>
  );
};

export default Footer;
