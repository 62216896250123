import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { API } from "../../Config/Api";
import { AuthContext } from "../../Context/Context";

const Contents = ({ id }) => {
  const { t, i18n } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [wait, setWait] = useState(false);
  useEffect(() => {
    setWait(true);
    axios
      .get(
        `${API.Project.client_project_get}/${id}?page=0&size=3&isPaginate=${0}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setData(res?.data?.data);
        setWait(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      {wait ? (
        <div
          style={{
            height: "160px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      ) : (
        data.map((item, index) => {
          return (
            <div
              className="description_en"
              key={index}
              style={{
                fontFamily: i18n.language === "ar" ? "Cairo" : "",
                direction: i18n.language === "ar" ? "rtl" : "",
                fontSize: i18n.language === "ar" ? "16px" : "",
              }}
            >
              {i18n.language === "ar"
                ? item.description_ar
                : item.description_en}
            </div>
          );
        })
      )}
      {data.length === 0 && (
        <div
          style={{
            height: "160px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "50px",
            color: "var(--Pr-color)",
          }}
        >
          No Details
        </div>
      )}
    </>
  );
};

export default Contents;
