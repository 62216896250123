import { createContext, useState } from "react";
import { useTranslation } from "react-i18next";
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [t, i18n] = useTranslation();
  const [showSide, setShowSide] = useState(false);
  return (
    <AuthContext.Provider
      value={{
        menuOpen,
        setMenuOpen,
        loader,
        setLoader,
        t,
        i18n,
        showSide,
        setShowSide,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
