import React, { useContext } from "react";
import { Dialog } from "primereact/dialog";
import { AuthContext } from "../../Context/Context";

const DialogGroup = ({ visible, header, setVisible, width, children }) => {
  const { t, i18n } = useContext(AuthContext);
  return (
    <div className="card flex justify-content-center">
      <Dialog
        headerStyle={{
          color: "var(--Sec-color)",
          background: "var(--Pr-color)",
          padding: "12px 20px",
          direction: i18n.language === "ar" ? "rtl" : "",
          fontFamily: i18n.language === "ar" ? "Cairo" : "",
        }}
        contentStyle={{
          padding: "20px",
          background: "var(--bg-color)",
        }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        header={header}
        visible={visible}
        className="Dialog"
        style={{
          width: width,
        }}
        dismissableMask
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        {children}
      </Dialog>
    </div>
  );
};

export default DialogGroup;
