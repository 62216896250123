import React, { useContext, useEffect, useState } from "react";
import Header from "./Component/Header/Header";
import Home from "./Page/Home/Home";
import Service from "./Page/Service/Service";
import Contact from "./Page/Contact/Contact";
import Footer from "./Page/Footer/Footer";
import About from "./Page/About/About";
import MyBusiness from "./Page/MyBusiness/MyBusiness";
import axios from "axios";
import { API } from "./Config/Api";
import { AuthContext } from "./Context/Context";
import { Link } from "react-scroll";
import ScrollReveal from "scrollreveal";
import Info from "./Page/Info/Info";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper/modules";

// import required modules
import "swiper/css";
import { useMediaQuery } from "react-responsive";

const Layout = () => {
  const [dataCat, setDataCat] = useState();
  const { t, i18n, showSide, setShowSide } = useContext(AuthContext);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  useEffect(() => {
    axios
      .get(`${API.About.client_about_get}`, {
        headers: {
          "Accept-Language": "en",
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        setDataCat(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    const scrollRevealOption = {
      origin: "bottom",
      distance: "150px",
      duration: 1000,
    };
    const sr = ScrollReveal();
    sr.reveal(".header_image img", {
      ...scrollRevealOption,
      origin: "right",
    });
    sr.reveal(".header_content p", {
      ...scrollRevealOption,
      delay: 500,
    });
    sr.reveal(".header_content h1", {
      ...scrollRevealOption,
      delay: 1000,
    });
    sr.reveal(".header_btns", {
      ...scrollRevealOption,
      delay: 1500,
    });
  }, []);

  const [data, setData] = useState([]);
  const [wait, setWait] = useState(false);
  useEffect(() => {
    setWait(true);
    axios
      .get(`${API.Slider.client_slider_get}?page=0&size=3&isPaginate=${0}`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        setData(res?.data?.data);
        setWait(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  return (
    <>
      <Header />
      <div className={`slider home ${i18n.language === "ar" ? "rtl" : ""}`}>
        <Swiper
          loop={true}
          spaceBetween={10}
          navigation={false}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs, Autoplay]}
          className="mySwiper2"
        >
          {data.map((slide, index) => (
            <SwiperSlide key={index} className="list">
              <div className="item" key={index}>
                <img
                  src={`${API.Admin.Image}${slide.image_path}`}
                  alt={slide.title_en}
                  style={{
                    width: isMobile ? "100%" : "",
                    height: isMobile ? "100%" : "",
                    objectFit: isMobile ? "cover" : "",
                    objectPosition: isMobile ? "-150px" : "",
                  }}
                />
                <div
                  className="content"
                  style={{
                    right: i18n.language === "ar" ? "5%" : "",
                    left: i18n.language === "ar" ? "unset" : "",
                  }}
                >
                  <div
                    className="title"
                    style={{
                      fontFamily: i18n.language === "ar" ? "Cairo" : "",
                      fontSize: isMobile ? "40px" : "50px",
                      marginBottom: isMobile ? "10px" : "10px",
                      direction: i18n.language === "ar" ? "rtl" : "",
                      letterSpacing: i18n.language === "ar" ? "0" : "",
                    }}
                  >
                    {i18n.language === "ar" ? slide.title_ar : slide.title_en}
                  </div>
                  <div
                    className="desc"
                    style={{
                      fontFamily: i18n.language === "ar" ? "Cairo" : "",
                      direction: i18n.language === "ar" ? "rtl" : "",
                      fontSize: i18n.language === "ar" ? "16px" : "",
                    }}
                  >
                    {i18n.language === "ar"
                      ? slide.description_ar
                      : slide.description_en}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={true}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs, Autoplay]}
          className="mySwiper thumbnail"
          style={{
            right: i18n.language === "ar" ? "unset" : "",
            left: i18n.language === "ar" ? "2%" : "",
          }}
        >
          {data.map((slide, index) => (
            <SwiperSlide key={index} className="item">
              <img
                src={`${API.Admin.Image}${slide.image_path}`}
                alt={slide.title}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="up">
        <Link to="home" spy={true} smooth={true} offset={-100} duration={400}>
          <i className="bx bxs-plane"></i>
        </Link>
      </div>
      <Info dataCat={dataCat} />
      {/* <About dataCat={dataCat} /> */}
      <MyBusiness />
      <Contact dataCat={dataCat} />
      <Footer />
    </>
  );
};

export default Layout;
