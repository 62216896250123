import React, { useContext, useEffect, useState } from "react";
import "./Contact.css";
import { AuthContext } from "../../Context/Context";
import Toast from "../../Component/Sweetalert/Sweetalert";
import { API } from "../../Config/Api";
import axios from "axios";

const Contact = ({ dataCat }) => {
  const { t, i18n } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", email);
    formData.append("full_name", subject);
    formData.append("question", description);
    axios
      .post(API.Contact.client_contact_add, formData, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          Toast.fire({
            text: `${res.data.message}`,
            icon: "success",
          });
          setEmail("");
          setSubject("");
          setDescription("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <section className="contact section_container">
      <h2
        style={{
          fontFamily: i18n.language === "ar" ? "Cairo" : "",
          letterSpacing: i18n.language === "ar" ? "0" : "",
        }}
        className="section_header"
      >
        {t("menu.contact")}
      </h2>
      <p
        style={{
          fontFamily: i18n.language === "ar" ? "Cairo" : "",
          letterSpacing: i18n.language === "ar" ? "0" : "",
        }}
        className="section_description"
      >
        {t("Contact_p")}
      </p>

      <div className="contact_b_l">
        <div className={`infor ${i18n.language === "ar" ? "right" : ""}`}>
          <div className="item map">
            <i className="bx bx-map"></i>
            <label
              style={{
                fontFamily: i18n.language === "ar" ? "Cairo" : "",
                letterSpacing: i18n.language === "ar" ? "0" : "",
              }}
            >
              {i18n.language === "ar" ? dataCat.address_ar : dataCat?.address_en}
            </label>
          </div>
          <div className="item phone">
            <i className="bx bx-phone"></i>
            <label
              style={{
                direction: i18n.language === "ar" ? "ltr" : "",
              }}
            >
              {dataCat?.phone_mobile}
            </label>
          </div>
          <div className="item email">
            <i className="bx bxl-gmail"></i>
            <label>{dataCat?.email}</label>
          </div>
        </div>
        <div
          className="social_media"
          style={{
            justifyContent: i18n.language === "ar" ? "flex-start" : "",
          }}
        >
          <div className="children1">
            <a
              href={dataCat?.instagram}
              target="_blank"
              className="Btn Btn_insta"
            >
              <span className="svgContainer">
                <i className="bx bxl-instagram svgIcon"></i>
              </span>
              <span className="BG"></span>
            </a>
          </div>
          <div className="children2">
            <a
              href={dataCat?.facebook}
              target="_blank"
              className="Btn Btn_face"
            >
              <span className="svgContainer">
                <i className="bx bxl-facebook svgIcon"></i>
              </span>
              <span className="BG"></span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
