import React, { useContext, useEffect } from "react";
import "./Info.css";
import ScrollReveal from "scrollreveal";
import { AuthContext } from "../../Context/Context";

const Info = ({ dataCat }) => {
  const { t, i18n } = useContext(AuthContext);
  useEffect(() => {
    const scrollRevealOption = {
      origin: "bottom",
      distance: "150px",
      duration: 500,
    };
    const sr = ScrollReveal();

    sr.reveal(".right", {
      ...scrollRevealOption,
      origin: "top",
      delay: 400,
    });
    sr.reveal(".Info_content .section_description", {
      ...scrollRevealOption,
      origin: "bottom",
      delay: 500,
    });
  }, []);
  return (
    <section className="Info section_container Info_container about">
      <h2
        className="section_header"
        style={{
          direction: i18n.language === "ar" ? "rtl" : "",
          fontFamily: i18n.language === "ar" ? "Cairo" : "",
        }}
      >
        {t("about_header")}
      </h2>
      <div
        className="Info_content"
        style={{
          direction: i18n.language === "ar" ? "rtl" : "",
          fontFamily: i18n.language === "ar" ? "Cairo" : "",
        }}
      >
        <div className="right">
          <img
            src={require("../../assets/travel/airplane-8765259_1920.jpg")}
            alt=""
          />
        </div>
        <p
          className="section_description"
          style={{
            direction: i18n.language === "ar" ? "rtl" : "",
            fontFamily: i18n.language === "ar" ? "Cairo" : "",
          }}
        >
          {i18n.language === "ar"
            ? dataCat.main_description_ar
            : dataCat?.main_description_en}
        </p>
      </div>
    </section>
  );
};

export default Info;
