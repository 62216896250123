import React, { useContext, useEffect, useState } from "react";
import "./MyBusiness.css";
import axios from "axios";
import { API } from "../../Config/Api";
import { Paginator } from "primereact/paginator";
import { AuthContext } from "../../Context/Context";
import DialogGroup from "../../Component/DialogGroup/DialogGroup";
import FormGroup from "../../Component/FormGroup/FormGroup";
import Contacts from "./Contents";

const MyBusiness = () => {
  const { t, i18n } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [wait, setWait] = useState(false);
  const pageSize = 3;
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");
  const onPageChange = (event) => {
    let currentPage = event.page + 1;
    setCurrentPage(currentPage);
    setFirst(event.first);
    setRows(event.rows);
    setWait(true);
    axios
      .get(
        `${
          API.Project.client_project_get
        }?page=${currentPage}&size=${pageSize}&isPaginate=${1}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        setData(res?.data?.data?.data);
        setTotalItems(res?.data?.data?.totalItems);
        setWait(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    setWait(true);
    axios
      .get(
        `${
          API.Project.client_project_get
        }?page=${currentPage}&size=${pageSize}&isPaginate=${1}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setData(res?.data?.data?.data);
        setTotalItems(res?.data?.data?.totalItems);
        setWait(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentPage]);

  const [visible, setVisible] = useState(false);
  const [id, setId] = useState("");
  return (
    <>
      {visible && (
        <DialogGroup
          width="40vw"
          visible={visible}
          setVisible={setVisible}
          header={`${
            i18n.language === "ar" ? "تفاصيل الخدمة" : "Details Service"
          }`}
        >
          <FormGroup>
            <Contacts id={id} />
          </FormGroup>
        </DialogGroup>
      )}

      <section className="services section_container journey_container">
        <h2
          className="section_header"
          style={{
            direction: i18n.language === "ar" ? "rtl" : "",
            fontFamily: i18n.language === "ar" ? "Cairo" : "",
          }}
        >
          {t("about_header")}
        </h2>
        <p
          className="section_description"
          style={{
            direction: i18n.language === "ar" ? "rtl" : "",
            letterSpacing: i18n.language === "ar" ? "0" : "",
            fontSize: i18n.language === "ar" ? "18px" : "",
            fontFamily: i18n.language === "ar" ? "Cairo" : "",
          }}
        >
          {t("about_p")}
        </p>
        <div className={`journey_grid ${i18n.language === "ar" ? "gap" : ""}`}>
          {wait ? (
            <div
              style={{
                height: "460px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span className="loader"></span>
            </div>
          ) : (
            data.map((item, index) => {
              return (
                <div
                  key={index}
                  className="journey_card"
                  onClick={() => {
                    setVisible(!visible);
                    setId(item.id);
                  }}
                >
                  <div className="imgBx">
                    <img src={`${API.Admin.Image}${item.image_path}`} alt="" />
                  </div>
                  <div className="content">
                    <span className="price">
                      <p>{item.id}</p>
                    </span>
                    <h2
                      style={{
                        fontFamily: i18n.language === "ar" ? "Cairo" : "",
                        direction: i18n.language === "ar" ? "rtl" : "",
                        letterSpacing: i18n.language === "ar" ? "0" : "",
                        fontSize: i18n.language === "ar" ? "25px" : "",
                      }}
                    >
                      {i18n.language === "ar" ? item.title_ar : item.title_en}
                    </h2>
                    <p
                      style={{
                        fontFamily: i18n.language === "ar" ? "Cairo" : "",
                        direction: i18n.language === "ar" ? "rtl" : "",
                        fontSize: i18n.language === "ar" ? "16px" : "",
                      }}
                    >
                      {i18n.language === "ar"
                        ? item.description_ar
                        : item.description_en}
                    </p>
                  </div>
                </div>
              );
            })
          )}
        </div>
        <div
          className="paginator"
          style={{ marginTop: i18n.language === "ar" ? "5rem" : "" }}
        >
          <Paginator
            first={first}
            rows={rows}
            totalRecords={totalItems}
            // rowsPerPageOptions={[10, 20, 30]}
            onPageChange={onPageChange}
          />
        </div>
      </section>
    </>
  );
};

export default MyBusiness;
